import { call, put, takeEvery } from "redux-saga/effects";
import {
  START_GET_GEO_LOCATION,
  SUCCESS_GET_GEO_LOCATION,
  ERROR_GET_GEO_LOCATION,
  START_REGISTER_USER,
  SUCCESS_REGISTER_USER,
  ERROR_REGISTER_USER,
  START_LOGIN_USER,
  SUCCESS_LOGIN_USER,
  ERROR_LOGIN_USER,
  START_GET_USER,
  SUCCESS_GET_USER,
  ERROR_GET_USER,
  START_FORGOT_PASSWORD,
  SUCCESS_FORGOT_PASSWORD,
  ERROR_FORGOT_PASSWORD,
  START_RESET_PASSWORD,
  SUCCESS_RESET_PASSWORD,
  ERROR_RESET_PASSWORD,
  START_UPDATE_USER_PROFILE_IMAGE,
  SUCCESS_UPDATE_USER_PROFILE_IMAGE,
  ERROR_UPDATE_USER_PROFILE_IMAGE,
  START_UPDATE_COVER_IMAGE,
  SUCCESS_UPDATE_COVER_IMAGE,
  ERROR_UPDATE_COVER_IMAGE,
  START_UPDATE_USER_DETAILS,
  SUCCESS_UPDATE_USER_DETAILS,
  ERROR_UPDATE_USER_DETAILS,
  START_DELETE_PROFILE_IMAGE,
  SUCCESS_DELETE_PROFILE_IMAGE,
  ERROR_DELETE_PROFILE_IMAGE,
  START_DELETE_COVER_IMAGE,
  SUCCESS_DELETE_COVER_IMAGE,
  ERROR_DELETE_COVER_IMAGE,
  START_GET_OTHER_USER_PROFILE,
  SUCCESS_GET_OTHER_USER_PROFILE,
  ERROR_GET_OTHER_USER_PROFILE,
  START_CHECK_USERNAME_EXISTS,
  SUCCESS_CHECK_USERNAME_EXISTS,
  ERROR_CHECK_USERNAME_EXISTS,
  START_UPDATE_USERNAME,
  SUCCESS_UPDATE_USERNAME,
  ERROR_UPDATE_USERNAME,
  START_UPDATE_PHONE_NUMBER,
  SUCCESS_UPDATE_PHONE_NUMBER,
  ERROR_UPDATE_PHONE_NUMBER,
  START_UPDATE_PASSWORD,
  SUCCESS_UPDATE_PASSWORD,
  ERROR_UPDATE_PASSWORD,
  START_FORGOT_PASSWORD_CODE_SEND,
  SUCCESS_FORGOT_PASSWORD_CODE_SEND,
  ERROR_FORGOT_PASSWORD_CODE_SEND,
  START_VERIFY_EMAIL,
  SUCCESS_VERIFY_EMAIL,
  ERROR_VERIFY_EMAIL,
  START_VERIFY_EMAIL_CODE,
  SUCCESS_VERIFY_EMAIL_CODE,
  ERROR_VERIFY_EMAIL_CODE,
  START_UPDATE_EMAIL,
  SUCCESS_UPDATE_EMAIL,
  ERROR_UPDATE_EMAIL,
  START_VERIFY_UPDATE_EMAIL_CODE,
  SUCCESS_VERIFY_UPDATE_EMAIL_CODE,
  ERROR_VERIFY_UPDATE_EMAIL_CODE,
  START_DELETE_ACCOUNT,
  SUCCESS_DELETE_ACCOUNT,
  ERROR_DELETE_ACCOUNT,
  START_CHANGE_ACTIVITY_STATUS,
  SUCCESS_CHANGE_ACTIVITY_STATUS,
  ERROR_CHANGE_ACTIVITY_STATUS,
  START_LOG_OUT_USER,
  SUCCESS_LOG_OUT_USER,
  ERROR_LOG_OUT_USER,
  START_GET_SUGGESTIONS,
  SUCCESS_GET_SUGGESTIONS,
  ERROR_GET_SUGGESTIONS,
  START_SUBSCRIBE_USER,
  SUCCESS_SUBSCRIBE_USER,
  ERROR_SUBSCRIBE_USER,
  START_GET_FOLLOWINGS,
  START_GET_FOLLOWERS,
  SUCCESS_GET_FOLLOWINGS,
  ERROR_GET_FOLLOWINGS,
  SUCCESS_GET_FOLLOWERS,
  ERROR_GET_FOLLOWERS,
  START_BLOCK_USER,
  START_UNBLOCK_USER,
  START_GET_BLOCKED_USER_LIST,
  SUCCESS_BLOCK_USER,
  ERROR_BLOCK_USER,
  SUCCESS_UNBLOCK_USER,
  ERROR_UNBLOCK_USER,
  SUCCESS_GET_BLOCKED_USER_LIST,
  ERROR_GET_BLOCKED_USER_LIST,
  START_ADD_COUNTRY_LEGAL,
  SUCCESS_ADD_COUNTRY_LEGAL,
  ERROR_ADD_COUNTRY_LEGAL,
  START_IDV_PROCESS,
  SUCCESS_IDV_PROCESS,
  ERROR_IDV_PROCESS,
  START_REPORT,
  SUCCESS_REPORT,
  ERROR_REPORT,
  START_ADD_SOCIAL_MEDIA,
  SUCCESS_ADD_SOCIAL_MEDIA,
  ERROR_ADD_SOCIAL_MEDIA,
  START_UPDATE_SOCIAL_MEDIA,
  START_DELETE_SOCIAL_MEDIA,
  SUCCESS_UPDATE_SOCIAL_MEDIA,
  ERROR_UPDATE_SOCIAL_MEDIA,
  SUCCESS_DELETE_SOCIAL_MEDIA,
  ERROR_DELETE_SOCIAL_MEDIA,
  START_CONNECT_USER,
  SUCCESS_CONNECT_USER,
  ERROR_CONNECT_USER,
  START_DISCONNECT_USER,
  SUCCESS_DISCONNECT_USER,
  ERROR_DISCONNECT_USER,
  START_SWITCH_ACCOUNT,
  SUCCESS_SWITCH_ACCOUNT,
  ERROR_SWITCH_ACCOUNT,
  START_VERIFY_OTP,
  SUCCESS_VERIFY_OTP,
  ERROR_VERIFY_OTP,
  START_STORE_CONCENT,
SUCCESS_STORE_CONCENT,
ERROR_STORE_CONCENT,
} from "../Actions/UserAction";
import API from "../service";

import {
  getUserGeolocationAPI,
  registerUserAPI,
  loginUserAPI,
  getUserAPI,
  storeConcentAPI,
  forgotPasswordAPI,
  resetPasswordAPI,
  profileImageAccessURLAPI,
  uploadImageAWSAPI,
  profileImageUpoadServerCall,
  coverImageAccessURLAPI,
  coverImageUpoadServerCall,
  updateUserProfileDetailAPI,
  deleteProfileImageAPI,
  deleteCoverImageAPI,
  getOtherUserProfileAPI,
  checkUsernameExistsAPI,
  updateUserNameAPI,
  updatePhoneAPI,
  updatePasswordAPI,
  sendForgotPassCodeAPI,
  verifyEmailAPI,
  verifyEmailCodeAPI,
  updateEmailAPI,
  verifyUpdateEmailAPI,
  deleteAccountAPI,
  showActivityStatusAPI,
  logoutUserAPI,
  getSuggestionAPI,
  subscribeUserAPI,
  getFollowingsAPI,
  getFollowersAPI,
  blockUserAPI,
  unblockUserAPI,
  getBlockedUSerAPI,
  addCountryLegalAPI,
  startIDVProcessAPI,
  reportAPI,
  addSocialMediaAPI,
  updateSocialMediaAPI,
  deleteSocialMediaAPI,
  connectUserAPI,
  disconnectUserAPI,
  switchAccountAPI,
  verifyOtpAPI,
} from "../Api/UserApi";
import { ADD_NEW_NOTIFICATION, RESET_CONFIG_STATE } from "Actions/ConfigAction";
import { getBankDetailAPI } from "Api/PayoutApi";
import { SUCCESS_GET_BANK_DETAIL } from "Actions/PayoutAction";
import { START_GET_SINGLE_LIST } from "Actions/ListActions";
import { START_GET_CHAT_LIST } from "Actions/ChatAction";


function* getGeolocationWorker() {
  try {
    const response = yield call(getUserGeolocationAPI);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_GEO_LOCATION,
        payload: response.data,
      });
    } else {
      yield put({
        type: ERROR_GET_GEO_LOCATION,
        payload: response,
      });
    }
  } catch (error) {}
}

function* registerUserWorker({ payload }) {
  try {
    const response = yield call(registerUserAPI, payload);
    if (response.status) {
      API.setAccessTokenInLocalStorage(response.token);
      yield put({
        type: SUCCESS_REGISTER_USER,
        payload: response,
        token: response.token,
      });
      const response1 = yield call(getUserAPI);
      if (response1.status) {
        yield put({
          type: SUCCESS_GET_USER,
          payload: response1,
        });
      }
    } else {
      yield put({
        type: ERROR_REGISTER_USER,
        payload: response,
      });
    }
  } catch (error) {}
}

function* loginUserWorker({ payload }) {
  try {
    const response = yield call(loginUserAPI, payload);
    if (response.status) {
      API.setAccessTokenInLocalStorage(response.token);
      yield put({
        type: SUCCESS_LOGIN_USER,
        payload: response,
        token: response.token,
      });
      let response1 = yield call(getUserAPI, { basic: true });
      if (response1.status) {
        yield put({
          type: SUCCESS_GET_USER,
          payload: response1,
        });
        yield put({
          type: START_GET_CHAT_LIST,
          payload: {
            filter:"all",
            limit:10,
            offset:0,
            order_by:"recent",
            search:""
          },
        });
        response1 = yield call(getUserAPI);
        if (response1.status) {
          yield put({
            type: SUCCESS_GET_USER,
            payload: response1,
          });
        } else {
          yield put({
            type: ERROR_GET_USER,
            payload: response1,
          });
        }
      } else {
        yield put({
          type: ERROR_GET_USER,
          payload: response1,
        });
      }
      const response2 = yield call(getBankDetailAPI);
      if (response2.status) {
        yield put({
          type: SUCCESS_GET_BANK_DETAIL,
          payload: response2,
        });
      }
    } else {
      yield put({
        type: ERROR_LOGIN_USER,
        payload: response,
      });
      if (response?.two_step_auth_enabled || response?.two_step_auth_enabled_email) {
        localStorage.setItem("authToken", response.token);
        yield put({
          type: RESET_CONFIG_STATE,
          key: "openGlobalAuth",
          payload: false,
        });
      }
    }
  } catch (error) {
    yield put({
      type: ERROR_LOGIN_USER,
      payload: error.message,
    });
  }
}

function* verifyOtpWorker({ payload }) {
  try {
    const response = yield call(verifyOtpAPI, payload);
    if (response.status) {
      API.setAccessTokenInLocalStorage(response.token);
      yield put({
        type: SUCCESS_VERIFY_OTP,
        payload: response,
        token: response.token,
      });
      const response1 = yield call(getUserAPI);
      if (response1.status) {
        yield put({
          type: SUCCESS_GET_USER,
          payload: response1,
        });
      }
    } else {
      yield put({
        type: ERROR_VERIFY_OTP,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getUserWorker() {
  try {
    let response = yield call(getUserAPI, { basic: true });

    if (response?.unauthenticated) {
      window.location.href = "/login";
      window.localStorage.removeItem("token");
      return;
    }

    if (response.status) {
      yield put({
        type: SUCCESS_GET_USER,
        payload: response,
      });

      response = yield call(getUserAPI);
      if (response.status) {
        yield put({
          type: SUCCESS_GET_USER,
          payload: response,
        });
      } else {
        yield put({
          type: ERROR_GET_USER,
          payload: response,
        });
      }
    } else {
      yield put({
        type: ERROR_GET_USER,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: ERROR_GET_USER,
      payload: error.message,
    });
  }
}

function* storeConcentWorker({ payload }) {
  try {
    const response = yield call(storeConcentAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_STORE_CONCENT,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_STORE_CONCENT,
        payload: response,
      });
    }
  } catch (error) {}
}

function* forgotPasswordWorker({ payload }) {
  try {
    const response = yield call(forgotPasswordAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_FORGOT_PASSWORD,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_FORGOT_PASSWORD,
        payload: response,
      });
    }
  } catch (error) {}
}

function* resetPasswordWorker({ payload, token }) {
  try {
    const response = yield call(resetPasswordAPI, payload, token);
    if (response.status) {
      API.setAccessTokenInLocalStorage(response?.token);
      yield put({
        type: SUCCESS_RESET_PASSWORD,
        payload: response,
        token: response.token,
      });
      const response1 = yield call(getUserAPI);
      if (response1.status) {
        yield put({
          type: SUCCESS_GET_USER,
          payload: response1,
        });
      }
    } else {
      yield put({
        type: ERROR_RESET_PASSWORD,
        payload: response,
      });
      if (response?.two_step_auth_enabled || response?.two_step_auth_enabled_email) {
        localStorage.setItem("authToken", response.token);
      }
    }
  } catch (error) {}
}

function* updateProfileImageWorker({ file_key, file }) {
  try {
    const response = yield call(profileImageAccessURLAPI, file_key);
    if (response.status) {
      const response1 = yield call(uploadImageAWSAPI, response?.result, file);
      if (response1) {
        const response2 = yield call(
          profileImageUpoadServerCall,
          response?.signedurl?.fields?.key
        );
        if (response2?.status) {
          yield put({
            type: SUCCESS_UPDATE_USER_PROFILE_IMAGE,
            payload: response2?.access_url,
          });
        } else {
          yield put({
            type: ERROR_UPDATE_USER_PROFILE_IMAGE,
            payload: response2,
          });
        }
      } else {
        yield put({
          type: ERROR_UPDATE_USER_PROFILE_IMAGE,
          payload: response1,
        });
      }
    } else {
      yield put({
        type: ERROR_UPDATE_USER_PROFILE_IMAGE,
        payload: response,
      });
    }
  } catch (error) {}
}

function* updateCoverImageWorker({ file_key, file }) {
  try {
    const response = yield call(coverImageAccessURLAPI, file_key);
    if (response.status) {
      const response1 = yield call(uploadImageAWSAPI, response?.result, file);
      if (response1) {
        const response2 = yield call(
          coverImageUpoadServerCall,
          response?.signedurl?.fields?.key
        );
        if (response2?.status) {
          yield put({
            type: SUCCESS_UPDATE_COVER_IMAGE,
            payload: response2?.access_url,
          });
        } else {
          yield put({
            type: ERROR_UPDATE_COVER_IMAGE,
            payload: response2,
          });
        }
      } else {
        yield put({
          type: ERROR_UPDATE_COVER_IMAGE,
          payload: response1,
        });
      }
    } else {
      yield put({
        type: ERROR_UPDATE_COVER_IMAGE,
        payload: response,
      });
    }
  } catch (error) {}
}

function* updateUserDetailsWorker({ params }) {
  try {
    const response = yield call(updateUserProfileDetailAPI, params);
    if (response?.status) {
      const response1 = yield call(getUserAPI);
      if (response1?.status) {
        yield put({
          type: SUCCESS_UPDATE_USER_DETAILS,
          payload: response,
        });
        yield put({
          type: SUCCESS_GET_USER,
          payload: response1,
        });
      }
    } else {
      yield put({
        type: ERROR_UPDATE_USER_DETAILS,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response?.message || "Something went wrong!",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* deleteProfileImageWorker() {
  try {
    const response = yield call(deleteProfileImageAPI);
    if (response.status) {
      yield put({
        type: SUCCESS_DELETE_PROFILE_IMAGE,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_DELETE_PROFILE_IMAGE,
        payload: response,
      });
    }
  } catch (error) {}
}

function* deleteCoverImageWorker() {
  try {
    const response = yield call(deleteCoverImageAPI);
    if (response.status) {
      yield put({
        type: SUCCESS_DELETE_COVER_IMAGE,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_DELETE_COVER_IMAGE,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getOtherUserProfileWorker({ user_id }) {
  try {
    const response = yield call(getOtherUserProfileAPI, user_id);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_OTHER_USER_PROFILE,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_GET_OTHER_USER_PROFILE,
        payload: response,
      });
    }
  } catch (error) {}
}

function* checkUsernameExistsWorker({ payload }) {
  try {
    const response = yield call(checkUsernameExistsAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_CHECK_USERNAME_EXISTS,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_CHECK_USERNAME_EXISTS,
        payload: response,
      });
    }
  } catch (error) {}
}

function* updateUsernameWorker({ payload }) {
  try {
    const response = yield call(updateUserNameAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_UPDATE_USERNAME,
        payload: response,
        username: payload?.username,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Username updated successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_USERNAME,
        payload: response,
      });
    }
  } catch (error) {}
}

function* updatePhoneNumberWorker({ payload }) {
  try {
    const response = yield call(updatePhoneAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_UPDATE_PHONE_NUMBER,
        payload: response,
        phone: payload?.mobile_number,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Phone number updated successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_PHONE_NUMBER,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response?.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* updatePasswordWorker({ payload }) {
  try {
    const response = yield call(updatePasswordAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_UPDATE_PASSWORD,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Password has been updated successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_UPDATE_PASSWORD,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response?.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* sendForgotPassCodeWorker() {
  try {
    const response = yield call(sendForgotPassCodeAPI);
    if (response.status) {
      yield put({
        type: SUCCESS_FORGOT_PASSWORD_CODE_SEND,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_FORGOT_PASSWORD_CODE_SEND,
        payload: response,
      });
    }
  } catch (error) {}
}

function* verifyEmailWorker({ payload }) {
  try {
    const response = yield call(verifyEmailAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_VERIFY_EMAIL,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Check your e-mail for verification link.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_VERIFY_EMAIL,
        payload: response,
      });
    }
  } catch (error) {}
}

function* verifyEmailCodeWorker({ payload }) {
  try {
    const response = yield call(verifyEmailCodeAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_VERIFY_EMAIL_CODE,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Email verified successfully.",
          type: "success",
        },
      });
      yield put ({ type: START_GET_USER })
    } else {
      yield put({
        type: ERROR_VERIFY_EMAIL_CODE,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Invalid link provided.",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* updateEmailWorker({ payload }) {
  try {
    const response = yield call(updateEmailAPI, payload);
    if (response?.status) {
      yield put({
        type: SUCCESS_UPDATE_EMAIL,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_UPDATE_EMAIL,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response?.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* verifyUpdateEmailWorker({ payload, email }) {
  try {
    const response = yield call(verifyUpdateEmailAPI, payload);
    if (response?.status) {
      yield put({
        type: SUCCESS_VERIFY_UPDATE_EMAIL_CODE,
        payload: response,
        email: email,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Email updated successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_VERIFY_UPDATE_EMAIL_CODE,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response?.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* deleteAccountWorker() {
  try {
    const response = yield call(deleteAccountAPI);
    if (response.status) {
      yield put({
        type: SUCCESS_DELETE_ACCOUNT,
        payload: response,
      });
      document.location.href = "/login";
      window.localStorage.removeItem("token");
    } else {
      yield put({
        type: ERROR_DELETE_ACCOUNT,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Something went wrong! Please try again.",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* changeActivityStatusWorker({ payload }) {
  try {
    const response = yield call(showActivityStatusAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_CHANGE_ACTIVITY_STATUS,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_CHANGE_ACTIVITY_STATUS,
        payload: response,
      });
    }
  } catch (error) {}
}

function* logoutUserWorker() {
  try {
    const response = yield call(logoutUserAPI);
    if (response?.status) {
      yield put({
        type: SUCCESS_LOG_OUT_USER,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_LOG_OUT_USER,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Something went wrong! Please try again later.",
        },
      });
    }
  } catch (error) {}
}

function* getSuggestionsWorker({ payload, refresh }) {
  try {
    const response = yield call(getSuggestionAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_SUGGESTIONS,
        payload: response,
        refresh,
      });
    } else {
      yield put({
        type: ERROR_GET_SUGGESTIONS,
        payload: response,
      });
    }
  } catch (error) {}
}

function* subscribeUserWorker({ payload, user_id }) {
  try {
    const response = yield call(subscribeUserAPI, payload, user_id);
    if (response.status) {
      yield put({
        type: SUCCESS_SUBSCRIBE_USER,
        payload: response,
        user_id: user_id,
        followStatus: payload?.follow,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: payload?.follow
            ? "User Subscribed successfully."
            : "User Unsubscribed successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_SUBSCRIBE_USER,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getFollowingsWorker({ payload }) {
  try {
    const response = yield call(getFollowingsAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_FOLLOWINGS,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_GET_FOLLOWINGS,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getFollowersWorker({ payload }) {
  try {
    const response = yield call(getFollowersAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_FOLLOWERS,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_GET_FOLLOWERS,
        payload: response,
      });
    }
  } catch (error) {}
}

function* blockUserWorker({ user_id, tab }) {
  try {
    const response = yield call(blockUserAPI, user_id);
    if (response.status) {
      yield put({
        type: SUCCESS_BLOCK_USER,
        payload: response,
        user_id: user_id,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "User blocked successfully",
          type: "success",
        },
      });
      if (tab) {
        yield put({
          type: START_GET_SINGLE_LIST,
          id: tab,
          isRefresh:true
        });
      }
    } else {
      yield put({
        type: ERROR_BLOCK_USER,
        payload: response,
      });
    }
  } catch (error) {}
}

function* unblockUSerworker({ user_id , tab }) {
  try {
    const response = yield call(unblockUserAPI, user_id);
    if (response.status) {
      yield put({
        type: SUCCESS_UNBLOCK_USER,
        payload: response,
        user_id: user_id,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "User unblocked successfully",
          type: "success",
        },
      });
      if (tab) {
        yield put({
          type: START_GET_SINGLE_LIST,
          id: tab,
          isRefresh: true
        });
      }
    } else {
      yield put({
        type: ERROR_UNBLOCK_USER,
        payload: response,
      });
    }
  } catch (error) {}
}

function* getBlockedUSerWorker({ payload }) {
  try {
    const response = yield call(getBlockedUSerAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_GET_BLOCKED_USER_LIST,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_GET_BLOCKED_USER_LIST,
        payload: response,
      });
    }
  } catch (error) {}
}

function* addCountryLegalWorker({ payload }) {
  try {
    const response = yield call(addCountryLegalAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_ADD_COUNTRY_LEGAL,
        payload: response,
        country: payload?.country,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Changes were saved",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_ADD_COUNTRY_LEGAL,
        payload: response,
      });
    }
  } catch (error) {}
}

function* startIdvProcessWorker() {
  try {
    const response = yield call(startIDVProcessAPI);
    if (response.status) {
      yield put({
        type: SUCCESS_IDV_PROCESS,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_IDV_PROCESS,
        payload: response,
      });
    }
  } catch (error) {}
}

function* reportWorker({ payload }) {
  try {
    const response = yield call(reportAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_REPORT,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_REPORT,
        payload: response,
      });
    }
  } catch (error) {}
}

function* addSocialMediaWorker({ payload }) {
  try {
    const response = yield call(addSocialMediaAPI, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_ADD_SOCIAL_MEDIA,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_ADD_SOCIAL_MEDIA,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response?.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* updateSocialMediaWorker({ button_id, payload }) {
  try {
    const response = yield call(updateSocialMediaAPI, button_id, payload);
    if (response.status) {
      yield put({
        type: SUCCESS_UPDATE_SOCIAL_MEDIA,
        payload: response,
      });
    } else {
      yield put({
        type: ERROR_UPDATE_SOCIAL_MEDIA,
        payload: response,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: response?.message,
          type: "error",
        },
      });
    }
  } catch (error) {}
}
function* deleteSocialMediaWorker({ button_id }) {
  try {
    const response = yield call(deleteSocialMediaAPI, button_id);
    if (response.status) {
      yield put({
        type: SUCCESS_DELETE_SOCIAL_MEDIA,
        payload: response,
        button_id,
      });
    } else {
      yield put({
        type: ERROR_DELETE_SOCIAL_MEDIA,
        payload: response,
      });
    }
  } catch (error) {}
}

function* connectUserWorker({ payload }) {
  try {
    const res = yield call(connectUserAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_CONNECT_USER,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_CONNECT_USER,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* disconnectUserWorker({ payload }) {
  try {
    const res = yield call(disconnectUserAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_DISCONNECT_USER,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_DISCONNECT_USER,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* switchAccountWorker({ payload }) {
  try {
    const res = yield call(switchAccountAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_SWITCH_ACCOUNT,
        payload: res,
      });
      window.location.reload();
      API.setAccessTokenInLocalStorage(res?.token);
    } else {
      yield put({
        type: ERROR_SWITCH_ACCOUNT,
        payload: res,
      });
    }
  } catch (error) {}
}

export function* UserWatcher() {
  yield takeEvery(START_REGISTER_USER, registerUserWorker);
  yield takeEvery(START_GET_GEO_LOCATION, getGeolocationWorker);  
  yield takeEvery(START_LOGIN_USER, loginUserWorker);
  yield takeEvery(START_VERIFY_OTP, verifyOtpWorker);
  yield takeEvery(START_GET_USER, getUserWorker);
  yield takeEvery(START_STORE_CONCENT, storeConcentWorker);
  yield takeEvery(START_FORGOT_PASSWORD, forgotPasswordWorker);
  yield takeEvery(START_RESET_PASSWORD, resetPasswordWorker);
  yield takeEvery(START_UPDATE_USER_PROFILE_IMAGE, updateProfileImageWorker);
  yield takeEvery(START_UPDATE_COVER_IMAGE, updateCoverImageWorker);
  yield takeEvery(START_UPDATE_USER_DETAILS, updateUserDetailsWorker);
  yield takeEvery(START_DELETE_PROFILE_IMAGE, deleteProfileImageWorker);
  yield takeEvery(START_DELETE_COVER_IMAGE, deleteCoverImageWorker);
  yield takeEvery(START_GET_OTHER_USER_PROFILE, getOtherUserProfileWorker);
  yield takeEvery(START_CHECK_USERNAME_EXISTS, checkUsernameExistsWorker);
  yield takeEvery(START_UPDATE_USERNAME, updateUsernameWorker);
  yield takeEvery(START_UPDATE_PHONE_NUMBER, updatePhoneNumberWorker);
  yield takeEvery(START_UPDATE_PASSWORD, updatePasswordWorker);
  yield takeEvery(START_FORGOT_PASSWORD_CODE_SEND, sendForgotPassCodeWorker);
  yield takeEvery(START_VERIFY_EMAIL, verifyEmailWorker);
  yield takeEvery(START_VERIFY_EMAIL_CODE, verifyEmailCodeWorker);
  yield takeEvery(START_UPDATE_EMAIL, updateEmailWorker);
  yield takeEvery(START_VERIFY_UPDATE_EMAIL_CODE, verifyUpdateEmailWorker);
  yield takeEvery(START_DELETE_ACCOUNT, deleteAccountWorker);
  yield takeEvery(START_CHANGE_ACTIVITY_STATUS, changeActivityStatusWorker);
  yield takeEvery(START_LOG_OUT_USER, logoutUserWorker);
  yield takeEvery(START_GET_SUGGESTIONS, getSuggestionsWorker);
  yield takeEvery(START_SUBSCRIBE_USER, subscribeUserWorker);
  yield takeEvery(START_GET_FOLLOWINGS, getFollowingsWorker);
  yield takeEvery(START_GET_FOLLOWERS, getFollowersWorker);
  yield takeEvery(START_BLOCK_USER, blockUserWorker);
  yield takeEvery(START_UNBLOCK_USER, unblockUSerworker);
  yield takeEvery(START_GET_BLOCKED_USER_LIST, getBlockedUSerWorker);
  yield takeEvery(START_ADD_COUNTRY_LEGAL, addCountryLegalWorker);
  yield takeEvery(START_IDV_PROCESS, startIdvProcessWorker);
  yield takeEvery(START_REPORT, reportWorker);
  yield takeEvery(START_ADD_SOCIAL_MEDIA, addSocialMediaWorker);
  yield takeEvery(START_UPDATE_SOCIAL_MEDIA, updateSocialMediaWorker);
  yield takeEvery(START_DELETE_SOCIAL_MEDIA, deleteSocialMediaWorker);
  yield takeEvery(START_CONNECT_USER, connectUserWorker);
  yield takeEvery(START_DISCONNECT_USER, disconnectUserWorker);
  yield takeEvery(START_SWITCH_ACCOUNT, switchAccountWorker);
}
