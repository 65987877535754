import API from "service";
import {
  ADD_BANKING_DETAILS_ENDPOINT,
  ADD_BANK_DOCUMENT_SERVER_CALL_ENDPOINT,
  ADD_PAYOUT_METHOD_ENDPOINT,
  CHANGE_PAYOUT_TYPE_ENDPOINT,
  DOWNLOAD_W9_FORM_ENDPOINT,
  GET_BANK_DETAIL_ENDPOINT,
  GET_EARNINGS_LIST_ENDPOINT,
  GET_EARNING_STATISTICS_ENDPOINT,
  GET_PAYOUT_REQUESTS_ENDPOINT,
  GET_REFERRAL_EARNINGS_ENDPOINT,
  GET_STATEMENT_DETAIL_ENDPOINT,
  MANUAL_PAYOUT_ENDPOINT,
  SEND_PAYOUT_SECURITY_CODE_ENDPOINT,
  SUBMIT_W8_FORM_ENDPOINT,
  SUBMIT_W9_FORM_ENDPOINT,
} from "./endpoints";

export const getBankDetailAPI = () => {
  return API.get(GET_BANK_DETAIL_ENDPOINT);
};

export const addBankDetailAPI = (payload) => {
  return API.post(ADD_BANKING_DETAILS_ENDPOINT, payload);
};

export const removeBankDetailAPI = (payload) => {
  return API.deleteM(ADD_BANKING_DETAILS_ENDPOINT, payload);
};

export const uploadDocumentOnAwsAPI = async (url, data) => {
  let fileCount = 0;
  let res = data?.map(
    async (file, i) =>
      await fetch(url[i], {
        method: "PUT",
        headers: {},
        body: file,
      }).then((res) => (fileCount += 1))
  );
  return Promise.all(res)?.then((res) => fileCount === data?.length);
};

export const addBankDocumentServerCallAPI = (payload) => {
  return API.post(ADD_BANK_DOCUMENT_SERVER_CALL_ENDPOINT, payload);
};

export const sendPayoutSecurityCodeAPI = (payload) => {
  return API.put(SEND_PAYOUT_SECURITY_CODE_ENDPOINT, payload);
};

export const addPayoutMethodAPI = (payload) => {
  return API.put(ADD_PAYOUT_METHOD_ENDPOINT, payload);
};

export const getStatementDetailAPI = () => {
  return API.get(GET_STATEMENT_DETAIL_ENDPOINT);
};

export const getEarningsListAPI = (payload) => {
  return API.get(GET_EARNINGS_LIST_ENDPOINT, payload);
};

export const changePayoutTypeAPI = (payload) => {
  return API.put(CHANGE_PAYOUT_TYPE_ENDPOINT, payload);
};

export const getPayoutRequestsAPI = (payload) => {
  return API.get(GET_PAYOUT_REQUESTS_ENDPOINT, payload);
};

export const getReferralsEarningsAPI = (payload) => {
  return API.get(GET_REFERRAL_EARNINGS_ENDPOINT, payload);
};

export const manualPayoutAPI = (payload) => {
  return API.post(MANUAL_PAYOUT_ENDPOINT, payload);
};

export const getEarningStatisticsAPI = (payload) => {
  return API.post(GET_EARNING_STATISTICS_ENDPOINT, payload);
};

export const submitW9FormAPI = (payload) => {
  return API.post(SUBMIT_W9_FORM_ENDPOINT, payload);
};

export const downlaodW9FormAPI = (payload) => {
  return API.get(DOWNLOAD_W9_FORM_ENDPOINT, payload);
};

export const submitW8FormAPI = (payload) => {
  return API.post(SUBMIT_W8_FORM_ENDPOINT, payload);
};
