export const RESET_PAYOUT_STATE = "RESET_PAYOUT_STATE";

export const START_GET_BANK_DETAIL = "START_GET_BANK_DETAIL";
export const SUCCESS_GET_BANK_DETAIL = "SUCCESS_GET_BANK_DETAIL";
export const ERROR_GET_BANK_DETAIL = "ERROR_GET_BANK_DETAIL";

export const START_ADD_BANKING_DETAILS = "START_ADD_BANKING_DETAILS";
export const SUCCESS_ADD_BANKING_DETAILS = "SUCCESS_ADD_BANKING_DETAILS";
export const ERROR_ADD_BANKING_DETAILS = "ERROR_ADD_BANKING_DETAILS";

export const START_REMOVE_BANKING_DETAILS = "START_REMOVE_BANKING_DETAILS";
export const SUCCESS_REMOVE_BANKING_DETAILS = "SUCCESS_REMOVE_BANKING_DETAILS";
export const ERROR_REMOVE_BANKING_DETAILS = "ERROR_REMOVE_BANKING_DETAILS";

export const START_SEND_PAYOUT_SECURITY_CODE =
  "START_SEND_PAYOUT_SECURITY_CODE";
export const SUCCESS_SEND_PAYOUT_SECURITY_CODE =
  "SUCCESS_SEND_PAYOUT_SECURITY_CODE";
export const ERROR_SEND_PAYOUT_SECURITY_CODE =
  "ERROR_SEND_PAYOUT_SECURITY_CODE";

export const START_ADD_PAYOUT_METHOD = "START_ADD_PAYOUT_METHOD";
export const SUCCESS_ADD_PAYOUT_METHOD = "SUCCESS_ADD_PAYOUT_METHOD";
export const ERROR_ADD_PAYOUT_METHOD = "ERROR_ADD_PAYOUT_METHOD";

export const START_GET_STATEMENT_DETAIL = "START_GET_STATEMENT_DETAIL";
export const SUCCESS_GET_STATEMENT_DETAIL = "SUCCESS_GET_STATEMENT_DETAIL";
export const ERROR_GET_STATEMENT_DETAIL = "ERROR_GET_STATEMENT_DETAIL";

export const START_GET_EARNINGS_LIST = "START_GET_EARNINGS_LIST";
export const SUCCESS_GET_EARNINGS_LIST = "SUCCESS_GET_EARNINGS_LIST";
export const ERROR_GET_EARNINGS_LIST = "ERROR_GET_EARNINGS_LIST";

export const START_CHANGE_PAYOUT_TYPE = "START_CHANGE_PAYOUT_TYPE";
export const SUCCESS_CHANGE_PAYOUT_TYPE = "SUCCESS_CHANGE_PAYOUT_TYPE";
export const ERROR_CHANGE_PAYOUT_TYPE = "ERROR_CHANGE_PAYOUT_TYPE";

export const START_GET_PAYOUT_REQUESTS = "START_GET_PAYOUT_REQUESTS";
export const SUCCESS_GET_PAYOUT_REQUESTS = "SUCCESS_GET_PAYOUT_REQUESTS";
export const ERROR_GET_PAYOUT_REQUESTS = "ERROR_GET_PAYOUT_REQUESTS";

export const START_GET_REFERRAL_EARNINGS = "START_GET_REFERRAL_EARNINGS";
export const SUCCESS_GET_REFERRAL_EARNINGS = "SUCCESS_GET_REFERRAL_EARNINGS";
export const ERROR_GET_REFERRAL_EARNINGS = "ERROR_GET_REFERRAL_EARNINGS";

export const START_MANUAL_PAYOUT = "START_MANUAL_PAYOUT";
export const SUCCESS_MANUAL_PAYOUT = "SUCCESS_MANUAL_PAYOUT";
export const ERROR_MANUAL_PAYOUT = "ERROR_MANUAL_PAYOUT";

export const START_GET_EARNING_STATISTICS = "START_GET_EARNING_STATISTICS";
export const SUCCESS_GET_EARNING_STATISTICS = "SUCCESS_GET_EARNING_STATISTICS";
export const ERROR_GET_EARNING_STATISTICS = "ERROR_GET_EARNING_STATISTICS";

export const START_SUBMIT_W9_FORM = "START_SUBMIT_W9_FORM";
export const SUCCESS_SUBMIT_W9_FORM = "SUCCESS_SUBMIT_W9_FORM";
export const ERROR_SUBMIT_W9_FORM = "ERROR_SUBMIT_W9_FORM";

export const START_DOWNLOAD_W9_FORM = "START_DOWNLOAD_W9_FORM";
export const SUCCESS_DOWNLOAD_W9_FORM = "SUCCESS_DOWNLOAD_W9_FORM";
export const ERROR_DOWNLOAD_W9_FORM = "ERROR_DOWNLOAD_W9_FORM";

export const START_SUBMIT_W8_FORM = "START_SUBMIT_W8_FORM";
export const SUCCESS_SUBMIT_W8_FORM = "SUCCESS_SUBMIT_W8_FORM";
export const ERROR_SUBMIT_W8_FORM = "ERROR_SUBMIT_W8_FORM";
