import { generateDynamicEndpoint } from "utils/generalUtils";
import API from "../service";
import {
  LOGIN_USER_ENDPOINT,
  REGISTER_USER_ENDPOINT,
  GET_USER_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  UPDATE_COVER_IMAGE_ENDPOINT,
  UPDATE_USER_PROFILE_IMAGE_ENDPOINT,
  UPDATE_USER_DETAIL_ENDPOINT,
  UPDATE_COVER_IMAGE_SERVER_ENDPOINT,
  UPDATE_PROFILE_IMAGE_SERVER_ENDPOINT,
  DELETE_PROFILE_IMAGE_ENDPOINT,
  DELETE_COVER_IMAGE_ENDPOINT,
  GET_OTHER_USER_PROFILE_ENDPOINT,
  CHECK_USERNAME_EXISTS_ENDPOINT,
  UPDATE_USERNAME_ENDPOINT,
  UPDATE_PHONE_ENDPOINT,
  UPDATE_PASSWORD_ENDPOINT,
  SEND_FORGOT_PASSOWRD_CODE_EXNPOINT,
  VERIFY_EMAIL_ENDPOINT,
  VERIFY_EMAIL_CODE_ENDPOINT,
  UPDATE_EMAIL_ENDPOiNT,
  VERIFY_UPDATE_EMAIL_CODE_ENDPOINT,
  DELETE_ACCOUNT_ENDPOINT,
  SHOW_ACTIVITY_STATUS_ENDPOINT,
  LOG_OUT_USER_ENDPOINT,
  GET_SUGGESTIONS_ENDPOINT,
  SUBSCRIBE_USER_ENDPOINT,
  GET_FOLLOWINGS_ENDPOINT,
  GET_FOLLOWERS_ENDPOINT,
  BLOCK_USER_ENDPOINT,
  UNBLOCK_USER_ENDPOINT,
  GET_BLOCKED_USER_ENDPOINT,
  ADD_COUNTRY_LEGAL_ENDPOINT,
  START_IDV_PROCESS_ENDPOINT,
  REPORT_ENDPOINT,
  ADD_SOCIAL_MEDIA_ENDPOINT,
  UPDATE_SOCIAL_MEDIA_ENDPOINT,
  DELETE_SOCIAL_MEDIA_ENDPOINT,
  CONNECT_USER_ENDPOINT,
  DISCONNECT_USER_ENDPOINT,
  SWITCH_ACCOUNT_ENDPOINT,
  VERIFY_OTP_CODE_ENDPOINT,
  GEO_LOCATION_ENDPOINT,
  CONCENT_ENDPOINT,
} from "./endpoints";

export const getUserGeolocationAPI = (payload) => {
  return API.get(GEO_LOCATION_ENDPOINT, payload);
};

export const registerUserAPI = (payload) => {
  return API.post(REGISTER_USER_ENDPOINT, payload);
};

export const loginUserAPI = (payload) => {
  return API.post(LOGIN_USER_ENDPOINT, payload);
};

export const verifyOtpAPI = (payload) => {
  return API.post(
    VERIFY_OTP_CODE_ENDPOINT,
    payload,
    localStorage.getItem("authToken")
  );
};

export const getUserAPI = (payload) => {
  return API.get(GET_USER_ENDPOINT,payload);
};

export const storeConcentAPI = (payload) => {
  return API.post(CONCENT_ENDPOINT,payload);
};


export const forgotPasswordAPI = (payload) => {
  return API.post(FORGOT_PASSWORD_ENDPOINT, payload);
};

export const resetPasswordAPI = (payload, token) => {
  return API.post(RESET_PASSWORD_ENDPOINT, payload, token);
};

export const profileImageAccessURLAPI = (file_key) => {
  return API.post(UPDATE_USER_PROFILE_IMAGE_ENDPOINT, { file_key });
};

export const uploadImageAWSAPI = (url, data) => {
  return fetch(url, {
    method: "PUT",
    headers: {},
    body: data,
  });
};

export const profileImageUpoadServerCall = (file_key) => {
  return API.post(UPDATE_PROFILE_IMAGE_SERVER_ENDPOINT, { file_key });
};

export const coverImageAccessURLAPI = (file_key) => {
  return API.post(UPDATE_COVER_IMAGE_ENDPOINT, { file_key });
};

export const coverImageUpoadServerCall = (file_key) => {
  return API.post(UPDATE_COVER_IMAGE_SERVER_ENDPOINT, { file_key });
};

export const updateUserProfileDetailAPI = (params) => {
  return API.put(UPDATE_USER_DETAIL_ENDPOINT, params);
};

export const deleteProfileImageAPI = () => {
  return API.deleteM(DELETE_PROFILE_IMAGE_ENDPOINT);
};

export const deleteCoverImageAPI = () => {
  return API.deleteM(DELETE_COVER_IMAGE_ENDPOINT);
};

export const getOtherUserProfileAPI = (user_id) => {
  return API.get(
    generateDynamicEndpoint(GET_OTHER_USER_PROFILE_ENDPOINT, { user_id })
  );
};

export const checkUsernameExistsAPI = (payload) => {
  return API.post(CHECK_USERNAME_EXISTS_ENDPOINT, payload);
};

export const updateUserNameAPI = (payload) => {
  return API.post(UPDATE_USERNAME_ENDPOINT, payload);
};

export const updatePhoneAPI = (payload) => {
  return API.post(UPDATE_PHONE_ENDPOINT, payload);
};

export const updatePasswordAPI = (payload) => {
  return API.post(UPDATE_PASSWORD_ENDPOINT, payload);
};

export const sendForgotPassCodeAPI = () => {
  return API.get(SEND_FORGOT_PASSOWRD_CODE_EXNPOINT);
};

export const verifyEmailAPI = (payload) => {
  return API.get(VERIFY_EMAIL_ENDPOINT, payload);
};

export const verifyEmailCodeAPI = (payload) => {
  return API.post(VERIFY_EMAIL_CODE_ENDPOINT, payload);
};

export const updateEmailAPI = (payload) => {
  return API.post(UPDATE_EMAIL_ENDPOiNT, payload);
};

export const verifyUpdateEmailAPI = (payload) => {
  return API.post(VERIFY_UPDATE_EMAIL_CODE_ENDPOINT, payload);
};

export const deleteAccountAPI = () => {
  return API.deleteM(DELETE_ACCOUNT_ENDPOINT);
};

export const showActivityStatusAPI = (payload) => {
  return API.post(SHOW_ACTIVITY_STATUS_ENDPOINT, payload);
};

export const logoutUserAPI = () => {
  return API.post(LOG_OUT_USER_ENDPOINT);
};

export const getSuggestionAPI = (payload) => {
  return API.get(GET_SUGGESTIONS_ENDPOINT, payload);
};

export const subscribeUserAPI = (payload, user_id) => {
  return API.post(
    generateDynamicEndpoint(SUBSCRIBE_USER_ENDPOINT, { user_id }),
    payload
  );
};

export const getFollowingsAPI = (payload) => {
  return API.get(GET_FOLLOWINGS_ENDPOINT, payload);
};

export const getFollowersAPI = (payload) => {
  return API.get(GET_FOLLOWERS_ENDPOINT, payload);
};

export const blockUserAPI = (user_id) => {
  return API.post(generateDynamicEndpoint(BLOCK_USER_ENDPOINT, { user_id }));
};

export const unblockUserAPI = (user_id) => {
  return API.deleteM(
    generateDynamicEndpoint(UNBLOCK_USER_ENDPOINT, { user_id })
  );
};

export const getBlockedUSerAPI = (payload) => {
  return API.post(GET_BLOCKED_USER_ENDPOINT, payload);
};

export const addCountryLegalAPI = (payload) => {
  return API.post(ADD_COUNTRY_LEGAL_ENDPOINT, payload);
};

export const startIDVProcessAPI = () => {
  return API.post(START_IDV_PROCESS_ENDPOINT);
};

export const reportAPI = (payload) => {
  return API.post(REPORT_ENDPOINT, payload);
};

export const addSocialMediaAPI = (payload) => {
  return API.post(ADD_SOCIAL_MEDIA_ENDPOINT, payload);
};

export const updateSocialMediaAPI = (button_id, payload) => {
  return API.put(
    generateDynamicEndpoint(UPDATE_SOCIAL_MEDIA_ENDPOINT, { button_id }),
    payload
  );
};

export const deleteSocialMediaAPI = (button_id) => {
  return API.deleteM(
    generateDynamicEndpoint(DELETE_SOCIAL_MEDIA_ENDPOINT, { button_id })
  );
};

export const connectUserAPI = (payload) => {
  return API.post(CONNECT_USER_ENDPOINT, payload);
};

export const disconnectUserAPI = (payload) => {
  return API.post(DISCONNECT_USER_ENDPOINT, payload);
};

export const switchAccountAPI = (paylaod) => {
  return API.post(SWITCH_ACCOUNT_ENDPOINT, paylaod);
};
