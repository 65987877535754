import { ADD_NEW_NOTIFICATION } from "Actions/ConfigAction";
import {
  ERROR_ADD_BANKING_DETAILS,
  ERROR_ADD_PAYOUT_METHOD,
  ERROR_CHANGE_PAYOUT_TYPE,
  ERROR_DOWNLOAD_W9_FORM,
  ERROR_GET_BANK_DETAIL,
  ERROR_GET_EARNINGS_LIST,
  ERROR_GET_EARNING_STATISTICS,
  ERROR_GET_PAYOUT_REQUESTS,
  ERROR_GET_REFERRAL_EARNINGS,
  ERROR_GET_STATEMENT_DETAIL,
  ERROR_MANUAL_PAYOUT,
  ERROR_SEND_PAYOUT_SECURITY_CODE,
  ERROR_SUBMIT_W8_FORM,
  ERROR_SUBMIT_W9_FORM,
  START_ADD_BANKING_DETAILS,
  START_ADD_PAYOUT_METHOD,
  START_CHANGE_PAYOUT_TYPE,
  START_DOWNLOAD_W9_FORM,
  START_GET_BANK_DETAIL,
  START_GET_EARNINGS_LIST,
  START_GET_EARNING_STATISTICS,
  START_GET_PAYOUT_REQUESTS,
  START_GET_REFERRAL_EARNINGS,
  START_GET_STATEMENT_DETAIL,
  START_MANUAL_PAYOUT,
  START_SEND_PAYOUT_SECURITY_CODE,
  START_SUBMIT_W8_FORM,
  START_SUBMIT_W9_FORM,
  SUCCESS_ADD_BANKING_DETAILS,
  SUCCESS_ADD_PAYOUT_METHOD,
  SUCCESS_CHANGE_PAYOUT_TYPE,
  SUCCESS_DOWNLOAD_W9_FORM,
  SUCCESS_GET_BANK_DETAIL,
  SUCCESS_GET_EARNINGS_LIST,
  SUCCESS_GET_EARNING_STATISTICS,
  SUCCESS_GET_PAYOUT_REQUESTS,
  SUCCESS_GET_REFERRAL_EARNINGS,
  SUCCESS_GET_STATEMENT_DETAIL,
  SUCCESS_MANUAL_PAYOUT,
  SUCCESS_SEND_PAYOUT_SECURITY_CODE,
  SUCCESS_SUBMIT_W8_FORM,
  SUCCESS_SUBMIT_W9_FORM,
  START_REMOVE_BANKING_DETAILS,
  SUCCESS_REMOVE_BANKING_DETAILS,
  ERROR_REMOVE_BANKING_DETAILS,
} from "Actions/PayoutAction";
import {
  addBankDetailAPI,
  addPayoutMethodAPI,
  changePayoutTypeAPI,
  downlaodW9FormAPI,
  getBankDetailAPI,
  getEarningsListAPI,
  getEarningStatisticsAPI,
  getPayoutRequestsAPI,
  getReferralsEarningsAPI,
  getStatementDetailAPI,
  manualPayoutAPI,
  removeBankDetailAPI,
  sendPayoutSecurityCodeAPI,
  submitW8FormAPI,
  submitW9FormAPI,
} from "Api/PayoutApi";
import { call, put, take, takeEvery } from "redux-saga/effects";

function* getBankDetailWorker() {
  try {
    const res = yield call(getBankDetailAPI);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_BANK_DETAIL,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_BANK_DETAIL,
        payload: res,
      });
    }
  } catch (error) {}
}

function* addBankingDetailWorker({ payload, files }) {
  try {
    const res = yield call(addBankDetailAPI, payload);
    if (res?.status) {
      yield put({
        type: SUCCESS_ADD_BANKING_DETAILS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_ADD_BANKING_DETAILS,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message:
            res?.message || "Something went wrong. Please try after sometime",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* removeBankingDetailWorker({ payload, files }) {
  try {
    const res = yield call(removeBankDetailAPI, payload);
    if (res?.status) {
      yield put({
        type: START_GET_BANK_DETAIL
      });
      yield put({
        type: SUCCESS_REMOVE_BANKING_DETAILS,
        payload: res,
      });
      if(payload?.onSuccess){
        payload?.onSuccess(res)
      }
      if(payload?.onError){
        payload?.onError(res)
      }
    } else {
      yield put({
        type: ERROR_REMOVE_BANKING_DETAILS,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message:
            res?.message || "Something went wrong. Please try after sometime",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* sendPayoutSecurityCodeWorker({ payload }) {
  try {
    const res = yield call(sendPayoutSecurityCodeAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_SEND_PAYOUT_SECURITY_CODE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Please check your email for the security code.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_SEND_PAYOUT_SECURITY_CODE,
        payload: res,
      });
    }
  } catch (error) {}
}

function* addPayoutMethodWorker({ payload }) {
  try {
    const res = yield call(addPayoutMethodAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_ADD_PAYOUT_METHOD,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_ADD_PAYOUT_METHOD,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message:
            res?.message || "Something went wrong. Please try after sometime",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getStatementDetailWorker() {
  try {
    const res = yield call(getStatementDetailAPI);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_STATEMENT_DETAIL,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_STATEMENT_DETAIL,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getEarningsListWorker({ payload }) {
  try {
    const res = yield call(getEarningsListAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_EARNINGS_LIST,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_EARNINGS_LIST,
        payload: res,
      });
    }
  } catch (error) {}
}

function* changePayoutTypeWorker({ payload }) {
  try {
    const res = yield call(changePayoutTypeAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_CHANGE_PAYOUT_TYPE,
        payload: res,
        payoutType: payload?.auto_payout_type,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: "Changes were saved successfully.",
          type: "success",
        },
      });
    } else {
      yield put({
        type: ERROR_CHANGE_PAYOUT_TYPE,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message:
            res?.message || "Something went wrong! Please try again later.",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getPayoutRequestsWorker({ payload }) {
  try {
    const res = yield call(getPayoutRequestsAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_PAYOUT_REQUESTS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_PAYOUT_REQUESTS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* getReferralsEarningsWorker({ payload }) {
  try {
    const res = yield call(getReferralsEarningsAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_REFERRAL_EARNINGS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_REFERRAL_EARNINGS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* manuaPayoutWorker({ payload }) {
  try {
    const res = yield call(manualPayoutAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_MANUAL_PAYOUT,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_MANUAL_PAYOUT,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message:
            res?.message || "Something went wrong, Please try after sometime",
          type: "error",
        },
      });
    }
  } catch (error) {}
}

function* getEarningStatisticsWorker({ payload }) {
  try {
    const res = yield call(getEarningStatisticsAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_GET_EARNING_STATISTICS,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_GET_EARNING_STATISTICS,
        payload: res,
      });
    }
  } catch (error) {}
}

function* submitW9FormWorker({ payload }) {
  try {
    const res = yield call(submitW9FormAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_SUBMIT_W9_FORM,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_SUBMIT_W9_FORM,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong",
          type: "errorf",
        },
      });
    }
  } catch (error) {}
}

function* downloadW9FormWorker({ payload }) {
  try {
    const res = yield call(downlaodW9FormAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_DOWNLOAD_W9_FORM,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_DOWNLOAD_W9_FORM,
        payload: res,
      });
    }
  } catch (error) {}
}

function* submitW8FormWorker({ payload }) {
  try {
    const res = yield call(submitW8FormAPI, payload);
    if (res.status) {
      yield put({
        type: SUCCESS_SUBMIT_W8_FORM,
        payload: res,
      });
    } else {
      yield put({
        type: ERROR_SUBMIT_W8_FORM,
        payload: res,
      });
      yield put({
        type: ADD_NEW_NOTIFICATION,
        payload: {
          message: res?.message || "Something went wrong",
          type: "errorf",
        },
      });
    }
  } catch (error) {}
}

export function* PayoutWatcher() {
  yield takeEvery(START_GET_BANK_DETAIL, getBankDetailWorker);
  yield takeEvery(START_ADD_BANKING_DETAILS, addBankingDetailWorker);
  yield takeEvery(START_REMOVE_BANKING_DETAILS, removeBankingDetailWorker);
  yield takeEvery(
    START_SEND_PAYOUT_SECURITY_CODE,
    sendPayoutSecurityCodeWorker
  );
  yield takeEvery(START_ADD_PAYOUT_METHOD, addPayoutMethodWorker);
  yield takeEvery(START_GET_STATEMENT_DETAIL, getStatementDetailWorker);
  yield takeEvery(START_GET_EARNINGS_LIST, getEarningsListWorker);
  yield takeEvery(START_CHANGE_PAYOUT_TYPE, changePayoutTypeWorker);
  yield takeEvery(START_GET_PAYOUT_REQUESTS, getPayoutRequestsWorker);
  yield takeEvery(START_GET_REFERRAL_EARNINGS, getReferralsEarningsWorker);
  yield takeEvery(START_MANUAL_PAYOUT, manuaPayoutWorker);
  yield takeEvery(START_GET_EARNING_STATISTICS, getEarningStatisticsWorker);
  yield takeEvery(START_SUBMIT_W9_FORM, submitW9FormWorker);
  yield takeEvery(START_DOWNLOAD_W9_FORM, downloadW9FormWorker);
  yield takeEvery(START_SUBMIT_W8_FORM, submitW8FormWorker);
}
